import { InView as InViewBase } from 'react-intersection-observer';

interface Props {
  onVisible: () => void;
  rootMargin?: string;
}

export default function InView({ onVisible, rootMargin = '200px' }: Props) {
  return (
    <InViewBase
      as="div"
      onChange={(inView) => {
        if (inView) {
          onVisible();
        }
      }}
      rootMargin={rootMargin}
      triggerOnce
    >
      &nbsp;
    </InViewBase>
  );
}
